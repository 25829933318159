import { setupPaths } from './globals';
import "import-map-overrides";
import { run } from './run';
import './assets/importmap.json';
function wireSpaPaths() {
    const baseElement = document.createElement("base");
    const baseHref = window.getAvetisSpaBase();
    baseElement.href = baseHref;
    document.head.appendChild(baseElement);
    __webpack_public_path__ = baseHref;
}
function runSpa(config) {
    const { offline = true } = config;
    return run(offline);
}
function initializeSpa(config) {
    setupPaths(config);
    wireSpaPaths();
    return runSpa(config);
}
window.initializeSpa = initializeSpa;
