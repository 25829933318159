var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "import-map-overrides";
import "systemjs/dist/system";
import "systemjs/dist/extras/amd";
import "systemjs/dist/extras/named-exports";
import "systemjs/dist/extras/named-register";
import "systemjs/dist/extras/use-default";
/**
 * Loads all provided modules by their name. Performs a
 * SystemJS import.
 * @param modules The names of the modules to resolve.
 */
export function loadModules(modules) {
    return Promise.all(modules.map((name) => System.import(name).then((value) => __awaiter(this, void 0, void 0, function* () {
        // first check if this is a new module-type -> then we have a remote-entry first
        if ("init" in value && "get" in value) {
            yield __webpack_init_sharing__("default");
            yield value.init(__webpack_share_scopes__.default);
            const factory = yield value.get("app");
            const newValue = factory();
            return [name, newValue];
        }
        // otherwise we can directly return
        return [name, value];
    }), (error) => {
        console.error("Failed to load module", name, error);
        return [name, {}];
    })));
}
export function registerModules(modules) {
    Object.keys(modules).forEach((name) => registerModule(name, modules[name]));
}
export function registerModule(name, resolve) {
    System.register(name, [], (_exports) => ({
        execute() {
            const content = resolve();
            if (content instanceof Promise) {
                return content.then((innerContent) => _exports(innerContent));
            }
            else {
                _exports(content);
                if (typeof content === "function") {
                    _exports("__esModule", true);
                    _exports("default", content);
                }
                else if (typeof content === "object") {
                    if (!("default" in content)) {
                        _exports("default", content);
                    }
                }
            }
        },
    }));
}
