var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { 
// attach,
checkStatus, getCustomProps, isVersionSatisfied, registerExtension, attach } from "@aecp/ui-common";
import { registerApplication } from "single-spa";
import { routePrefix, routeRegex, wrapLifecycle } from "./helpers";
const providedDeps = {
    "@aecp/ui-common": process.env.FRAMEWORK_VERSION,
};
const pages = [];
/**
 * Normalizes the activator function, i.e., if we receive a
 * string we'll prepend the SPA base (prefix). We'll also handle
 * the case of a supplied array.
 * @param activator The activator to preprocess.
 */
function preprocessActivator(activator) {
    if (Array.isArray(activator)) {
        const activators = activator.map(preprocessActivator);
        return (location) => activators.some((activator) => activator(location));
    }
    else if (typeof activator === "string") {
        return (location) => routePrefix(activator, location);
    }
    else if (activator instanceof RegExp) {
        return (location) => routeRegex(activator, location);
    }
    else {
        return activator;
    }
}
function trySetup(appName, setup) {
    try {
        return setup();
    }
    catch (error) {
        console.error(`Error when trying to set up the module`, appName, error);
        return undefined;
    }
}
function getLoader(load, resources, privilegeAndRoles) {
    if (typeof privilegeAndRoles === "object" && Array.isArray(privilegeAndRoles)) {
        load = wrapLifecycle(load, privilegeAndRoles);
    }
    if (typeof resources === "object") {
        const resourceKeys = Object.keys(resources);
        if (resourceKeys.length > 0) {
            return () => __awaiter(this, void 0, void 0, function* () {
                const data = yield Promise.all(resourceKeys.map((key) => resources[key]()));
                const dataProps = resourceKeys.reduce((props, name, index) => {
                    props[name] = data[index];
                    return props;
                }, {});
                const lifecycle = yield load();
                return Object.assign(Object.assign({}, lifecycle), { mount(props) {
                        return lifecycle.mount(Object.assign(Object.assign({}, dataProps), props));
                    },
                    update(props) {
                        return lifecycle.update(Object.assign(Object.assign({}, dataProps), props));
                    } });
            });
        }
    }
    return load;
}
function satisfiesDependencies(deps) {
    for (const depName of Object.keys(deps)) {
        const requiredDep = deps[depName];
        const providedDep = providedDeps[depName];
        if (!providedDep) {
            console.warn(`Missing dependency "${depName}".`);
            return false;
        }
        if (!isVersionSatisfied(requiredDep, providedDep)) {
            console.warn(`Unsatisfied dependency constraint for "${depName}". Available "${providedDep}", but required "${requiredDep}".`);
            return false;
        }
    }
    return true;
}
export function registerApp(appName, appExports) {
    var _a, _b, _c;
    const setup = appExports.setupAvetis;
    const dependencies = (_a = appExports.frontendDependencies) !== null && _a !== void 0 ? _a : {};
    if (!satisfiesDependencies(dependencies)) {
        console.error(`The MF "${appName}" failed to meet the requirements for its dependencies. It will be ignored.`);
    }
    else if (typeof setup === "function") {
        const result = trySetup(appName, setup);
        if (result && typeof result === "object") {
            const availableExtensions = (_b = result.extensions) !== null && _b !== void 0 ? _b : [];
            (_c = result.pages) === null || _c === void 0 ? void 0 : _c.forEach((p) => {
                var _a;
                pages.push(Object.assign(Object.assign({}, p), { appName, order: (_a = p.order) !== null && _a !== void 0 ? _a : 1 }));
            });
            if (typeof result.activate !== "undefined") {
                pages.push({
                    appName,
                    load: getLoader(result.lifecycle, result.resources),
                    route: result.activate,
                    offline: result.offline,
                    online: result.online,
                    privilegesAndRoles: result.privilegesAndRoles,
                    order: result.order || 1,
                });
            }
            availableExtensions.forEach((ext) => {
                tryRegisterExtension(appName, ext);
            });
        }
    }
}
export function finishRegisteringAllApps() {
    pages.sort((a, b) => a.order - b.order);
    // Create a div for each page. This ensures their DOM order.
    // If we don't do this, Single-SPA 5 will create the DOM element only once
    // the page becomes active, which makes it impossible to guarantee order.
    let index = 0;
    let lastAppName;
    for (let page of pages) {
        if (page.appName !== lastAppName) {
            index = 0;
            lastAppName = page.appName;
        }
        else {
            index++;
        }
        const name = `${page.appName}-page-${index}`;
        const div = document.createElement("div");
        div.id = `single-spa-application:${name}`;
        document.body.appendChild(div);
        tryRegisterPage(name, page);
    }
}
export function tryRegisterPage(appName, page) {
    const { route, load, online, offline, resources, privilegesAndRoles } = page;
    if (checkStatus(online, offline)) {
        const activityFn = preprocessActivator(route);
        const loader = getLoader(load, resources, privilegesAndRoles);
        registerApplication(appName, loader, (location) => activityFn(location), () => getCustomProps(online, offline));
    }
}
export function tryRegisterExtension(moduleName, extension) {
    var _a;
    const id = (_a = extension.id) !== null && _a !== void 0 ? _a : extension.name;
    const slots = extension.slots || [extension.slot];
    if (!id) {
        console.warn(`A registered extension definition is missing an id and thus cannot be registered.
To fix this, ensure that you define the "id" (or alternatively the "name") field inside the extension definition.`, extension);
        return;
    }
    if (!extension.load) {
        console.warn(`A registered extension definition is missing the loader and thus cannot be registered.
To fix this, ensure that you define a "load" function inside the extension definition.`, extension);
        return;
    }
    registerExtension(id, {
        load: getLoader(extension.load, extension.resources, extension.privilegesAndRoles),
        meta: extension.meta || {},
        order: extension.order,
        moduleName,
        offline: extension.offline,
        online: extension.online,
        route: extension.route,
    });
    for (const slot of slots) {
        if (slot) {
            attach(slot, id);
        }
    }
}
