import { getCurrentUser, userHasAccess } from "@aecp/ui-common";
const emptyLifecycle = {
    bootstrap() {
        return Promise.resolve();
    },
    mount() {
        return Promise.resolve();
    },
    unmount() {
        return Promise.resolve();
    },
};
// const userOpts: CurrentUserWithoutResponseOption = {
//     includeAuthStatus: false,
// };
export function routePrefix(prefix, location) {
    return location.pathname.startsWith(window.getAvetisSpaBase() + prefix);
}
export function routeRegex(regex, location) {
    const result = regex.test(location.pathname.replace(window.getAvetisSpaBase(), ""));
    return result;
}
export function wrapLifecycle(load, requiredPrivilegesAndRoles) {
    return () => {
        return new Promise((resolve) => {
            const sub = getCurrentUser().subscribe((user) => {
                sub.unsubscribe();
                if (user && "user" in user && userHasAccess(requiredPrivilegesAndRoles, user.user)) {
                    return resolve(load());
                }
                return resolve(emptyLifecycle);
            });
        });
    };
}
