import * as react from "react";
import * as reactDom from "react-dom";
import * as reactRouterDom from "react-router-dom";
import * as reactI18next from "react-i18next";
import * as singleSpa from "single-spa";
import * as i18next from "i18next";
import * as antd from "antd";
import * as AntdIcons from "@ant-design/icons";
import * as AvetisUICommon from "@aecp/ui-common";
import * as rxjs from "rxjs";
export const sharedDependencies = {
    i18next: () => i18next,
    react: () => react,
    "react-dom": () => reactDom,
    "react-router-dom": () => reactRouterDom,
    "react-i18next": () => reactI18next,
    "single-spa": () => singleSpa,
    "antd": () => antd,
    "@ant-design/icons": () => AntdIcons,
    "@aecp/ui-common": () => AvetisUICommon,
    "rxjs": () => rxjs
};
