var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { registerModules, loadModules } from './system';
import { sharedDependencies } from "./dependencies";
import { registerApp, finishRegisteringAllApps } from "./apps";
import { subscribeConnectivity, dispatchConnectivityChanged, renderLoadingSpinner, getCurrentUser, navigate } from "@aecp/ui-common";
import { start, getAppNames, unregisterApplication } from "single-spa";
import { setupI18n } from "./locale";
import { message } from 'antd';
const allowedSuffixes = ["-app", "-widgets"];
function getApps(maps) {
    return Object.keys(maps).filter((m) => allowedSuffixes.some((n) => m.endsWith(n)));
}
/**
 * Loads the frontend modules (apps and widgets). Should be done *after*
 * the import maps initialized, i.e., after modules loaded.
 *
 * By convention we call frontend modules registering activation functions
 * apps, and all others widgets. This is not enforced technically.
 */
function loadApps() {
    return window.importMapOverrides
        .getCurrentPageMap()
        .then((importMap) => loadModules(getApps(importMap.imports)));
}
/**
 * Registers the extensions already coming from the app shell itself.
 */
// function registerCoreExtensions() {
//     const extensions = getCoreExtensions();
//     for (const extension of extensions) {
//         tryRegisterExtension(appName, extension);
//     }
// }
/**
 * Sets up the frontend modules (apps). Uses the defined export
 * from the root modules of the apps, which should export a
 * special function called "setupOpenMRS".
 * That function returns an object that is used to feed Single
 * SPA.
 */
function setupApps(modules) {
    return __awaiter(this, void 0, void 0, function* () {
        modules.forEach(([appName, appExports]) => registerApp(appName, appExports));
        subscribeConnectivity(() => __awaiter(this, void 0, void 0, function* () {
            const appNames = getAppNames();
            yield Promise.all(appNames.map(unregisterApplication));
            finishRegisteringAllApps();
        }));
        window.installedModules = modules;
    });
}
/**
 * Loads the provided configurations and sets them in the system.
 */
// async function loadConfigs(configs: Array<{ name: string; value: Config }>) {
//     for (const config of configs) {
//         provide(config.value, config.name);
//     }
// }
/**
 * Invoked when the connectivity is changed.
 */
function connectivityChanged() {
    const online = navigator.onLine;
    dispatchConnectivityChanged(online);
    if (online) {
        message.success("Online.", 2.5);
    }
    else {
        message.error("Offline.", 2.5);
    }
}
/**
 * Runs the shell by importing the translations and starting single SPA.
 */
function runShell() {
    window.addEventListener("offline", connectivityChanged);
    window.addEventListener("online", connectivityChanged);
    return setupI18n()
        .catch((err) => console.error(`Failed to initialize translations`, err))
        .then(() => start());
}
function handleInitFailure(e) {
    console.error(e);
    if (localStorage.getItem("openmrs:devtools")) {
        clearDevOverrides();
    }
    message.error(e.message);
}
function clearDevOverrides() {
    for (const key of Object.keys(localStorage)) {
        if (key.startsWith("import-map-override:") &&
            !["import-map-override:react", "import-map-override:react-dom"].includes(key)) {
            localStorage.removeItem(key);
        }
    }
    location.reload();
}
function createConfigLoader(configUrls) {
    const loadingConfigs = Promise.all(configUrls.map((configUrl) => fetch(configUrl)
        .then((res) => res.json())
        .then((config) => ({
        name: configUrl,
        value: config,
    }))
        .catch((err) => {
        console.error(`Loading the config from "${configUrl}" failed.`, err);
        return {
            name: configUrl,
            value: {},
        };
    })));
    // return () => loadingConfigs.then(loadConfigs);
}
// function showNotifications() {
//     renderInlineNotifications(
//         document.querySelector(".omrs-inline-notifications-container")
//     );
//     return;
// }
// function showModals() {
//     renderModals(document.querySelector(".omrs-modals-container"));
//     return;
// }
function showLoadingSpinner() {
    return renderLoadingSpinner(document.body);
}
// async function setupServiceWorker() {
//     const sw = await registerOmrsServiceWorker(
//         `${window.getOpenmrsSpaBase()}service-worker.js`
//     );
//     registerSwEvents(sw);
//     if (navigator.onLine) {
//         try {
//             await Promise.all([precacheImportMap(), precacheSharedApiEndpoints()]);
//         } catch (e) {
//             showNotification({
//                 critical: true,
//                 title: "Offline Setup Error",
//                 description: `There was an error while preparing the website's offline mode. You can try reloading the page to potentially fix the error. Details: ${e}.`,
//             });
//         }
//     }
// }
// function registerSwEvents(sw: Workbox) {
//     sw.addEventListener("message", (e) => {
//         const event = e.data as KnownOmrsServiceWorkerEvents;
//         if (event.type === "networkRequestFailed") {
//             dispatchNetworkRequestFailed(event);
//         } else {
//             console.warn(
//                 `Received an unknown service worker event of type ${event.type}.`,
//                 event
//             );
//         }
//     });
// }
// async function precacheImportMap() {
//     const importMap = await window.importMapOverrides.getCurrentPageMap();
//     await messageOmrsServiceWorker({
//         type: "onImportMapChanged",
//         importMap,
//     });
// }
// async function precacheSharedApiEndpoints() {
//     // By default, cache the session endpoint.
//     // This ensures that a lot of user/session related functions also work offline.
//     const sessionPathUrl = new URL(
//         `${window.openmrsBase}/ws/rest/v1/session`,
//         window.location.origin
//     ).href;
//     await messageOmrsServiceWorker({
//         type: "registerDynamicRoute",
//         url: sessionPathUrl,
//         strategy: "network-first",
//     });
// }
// function setupOfflineStaticDependencyPrecaching() {
//     const precacheDelay = 1000 * 60 * 5;
//     let lastPrecache: Date | null = null;
//     subscribeOnlineAndLoginChange((online, hasLoggedInUser) => {
//         const hasExceededPrecacheDelay =
//             !lastPrecache ||
//             new Date().getTime() - lastPrecache.getTime() > precacheDelay;
//         if (hasLoggedInUser && online && hasExceededPrecacheDelay) {
//             lastPrecache = new Date();
//             dispatchPrecacheStaticDependencies();
//         }
//     });
// }
function subscribeOnlineAndLoginChange(cb) {
    let isOnline = false;
    let hasLoggedInUser = false;
    getCurrentUser().subscribe((user) => {
        if (!sessionStorage.getItem("token")) {
            navigate("login");
        }
        hasLoggedInUser = !user.authenticated;
        cb(isOnline, hasLoggedInUser);
    });
    subscribeConnectivity(({ online }) => {
        isOnline = online;
        cb(online, hasLoggedInUser);
    });
}
function setup() {
    subscribeOnlineAndLoginChange((online, hasLoggedInUser) => {
    });
}
export function run(offline) {
    const closeLoading = showLoadingSpinner();
    // const provideConfigs = createConfigLoader(configUrls);
    // integrateBreakpoints();
    // showModals();
    // showNotifications();
    // createAppState({});  
    // subscribeNotificationShown(showNotification);
    // subscribeToastShown(showToast);
    registerModules(sharedDependencies);
    // setupApiModule();
    // registerCoreExtensions();
    // if (offline) {
    //     setupServiceWorker();
    // }
    return loadApps()
        .then(setupApps)
        // .then(provideConfigs)
        .then(runShell)
        .then(setup)
        .catch(handleInitFailure)
        .then(closeLoading);
    // .then(setupOfflineStaticDependencyPrecaching);
}
